import React from 'react'
import { Entry, EntrySkeletonType } from 'contentful'
import PuppieCard from 'pages/home/components/puppies/components/puppieCard'
import './puppiesList.Module.scss'
import { useContentful } from 'context/contentfulContext'

export default function PuppiesList() {
  const { puppies } = useContentful()
  return (
    <div className="puppies__grid">
      {puppies
        ?.sort(
          (a, b) => parseInt(b.fields.id + '') - parseInt(a.fields.id + ''),
        )
        ?.map((puppie: Entry<EntrySkeletonType, undefined, string>) => {
          console.log(puppie)
          return (
            <PuppieCard
              key={Math.random() * 1000}
              id={parseInt(puppie.fields.id as string)}
              //eslint-disable-next-line
              images={(puppie?.fields.images as any[]).map(
                //eslint-disable-next-line
                (image: any) => image.fields.file.url,
              )}
              variant="full"
            />
          )
        })}
    </div>
  )
}
