import client from 'utils/contentful'

const fetchPuppies = async () => {
  try {
    const response = await client.getEntries({
      content_type: 'cockapooPuppie',
    })
    return response
  } catch (err) {
    console.log(err)
  }
}

export default {
  fetchPuppies,
}
