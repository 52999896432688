import React from 'react'
import { useParams } from 'react-router-dom'
import PuppieHeader from './components/puppieHeader/puppieHeader'
import Note from './components/note/note'
// import BackButton from 'components/ui/backButton/backButton'
import FAQ from './components/FAQ/FAQ'
import './puppie.Module.scss'

export default function Puppie() {
  const { id } = useParams()
  return (
    <div className="puppie">
      <PuppieHeader id={id + ''} />
      <Note />
      <FAQ />
    </div>
  )
}
