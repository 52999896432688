import contentfulService from 'services/contentfulService'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Entry, EntrySkeletonType } from 'contentful'

interface ContextProps {
  //eslint-disable-next-line
  puppies: Entry<EntrySkeletonType, undefined, string>[] | undefined
  loading: boolean
}

const ContentfulContext = createContext<ContextProps>({
  puppies: undefined,
  loading: true,
})

export const useContentful = () => {
  return useContext(ContentfulContext)
}

export const ContentfulProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [puppies, setPuppies] = useState<
    Entry<EntrySkeletonType, undefined, string>[] | undefined
  >(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  const fetchData = async () => {
    const puppies = await contentfulService.fetchPuppies()
    setPuppies(puppies?.items)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <ContentfulContext.Provider value={{ puppies, loading }}>
      {children}
    </ContentfulContext.Provider>
  )
}
