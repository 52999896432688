import React from 'react'
import './note.Module.scss'

export default function Note() {
  return (
    <div className="note">
      <div className="note__container">
        <label className="note__title">Please Note</label>
        <div className="note__separator" />
        <div className="note__body">
          <p className="note__text">
            Our website is always up to date. A puppies profile will
            automatically be removed once the puppy is adopted.
          </p>
          <p className="note__text">
            Pickup can be arranged on date available or within 4 days thereafter
          </p>
        </div>
      </div>
    </div>
  )
}
