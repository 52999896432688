import { ContentfulProvider } from 'context/contentfulContext'
import Layout from 'layout/layout'
import Puppie from 'pages/ puppie/puppie'
import Home from 'pages/home/home'
import Puppies from 'pages/puppies/puppies'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

export default function App() {
  return (
    <BrowserRouter basename="/">
      <ContentfulProvider>
        <Layout>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="puppies" element={<Puppies />} />
            <Route path="puppie/:id" element={<Puppie />} />
          </Routes>
        </Layout>
      </ContentfulProvider>
    </BrowserRouter>
  )
}
