import React from 'react'
import Button from 'components/ui/button/button'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
// import { FormatQuoteSharp } from '@mui/icons-material'
// import { reviews } from 'reviews'
import './testimonials.Module.scss'

export default function Testimonials() {
  return (
    <div className="testimonials__section" id="Testimonials">
      <div className="testimonials__container">
        <div className="testimonials__body">
          <SectionTitle
            title="Reviews from our Clients"
            // subtitle="See what our clients think about us"
          />
          <div className="testimonials__grid">
            <div className="testimonial__facebook__container">
              <iframe
                src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fcnportela%2Fposts%2Fpfbid02km4vajFUfW3LaGEqwGo1RYGs3YPHRfBp5b6CsNqxtxvBdJ4ddr6RoGuBWUiyquFYl&show_text=true&width=500"
                className="testimonial__facebook"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </div>

            <div className="testimonial__facebook__container">
              <iframe
                src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjoyce.lins.395%2Fposts%2Fpfbid02Rz2RFj2txmnAXyBjqSR3S26FdEm2fmfNXr7Xr8iyaJj5GWhwncixvcFM1biiyhqpl&show_text=true&width=500"
                className="testimonial__facebook"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </div>
            {/* {reviews.map((testimonial) => {
              return (
                <div key={testimonial.image} className="testimonials__card">
                  <div className="testimonial__card__header">
                    <div className="testimonial__card__icon">
                      <FormatQuoteSharp fontSize="inherit" />
                    </div>
                    <div className="testimonial__body">
                      <div
                        className="testimonial__image"
                        style={{
                          backgroundImage: `url("${testimonial.image}")`,
                        }}
                      />
                      <label className="testimonial__text">
                        {testimonial.text}
                      </label>
                    </div>
                  </div>
                </div>
              )
            })} */}
          </div>
          <div className="button__row">
            <Button
              onClick={() =>
                window.open('https://www.facebook.com/Cockapoospet/reviews')
              }
              className="testimonials__seemore"
              variant="primary"
            >
              Add a Review
            </Button>
            <Button
              onClick={() =>
                window.open('https://www.facebook.com/Cockapoospet/reviews')
              }
              className="testimonials__seemore"
              variant="tertiary"
            >
              See more
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
