import { Facebook } from '@mui/icons-material'
import Logo from 'components/ui/logo/logo'
import React from 'react'
import './footer.Module.scss'

export default function Footer() {
  return (
    <div className="footer__container">
      <div className="footer__body">
        <div className="footer__section">
          <Logo />
          <label className="footer__copyright">© All Rights Reserved</label>
        </div>
        <div className="footer__section">
          <div className="footer__link__group">
            <label className="footer__group__title">Find us at</label>

            <a
              className="footer__group__link"
              target="_blank"
              href="https://www.facebook.com/Cockapoospet"
              rel="noreferrer"
            >
              <label>Facebook</label>
              <Facebook />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
