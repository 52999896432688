import React from 'react'
import Hero from './components/hero/hero'
import WhyChooseUs from './components/whyChooseUse/whyChooseUs'
import About from './components/about/about'
import Puppies from './components/puppies/puppies'
import Testimonials from './components/testimonials/testimonials'
import Contact from './components/contact/contact'

export default function Home() {
  return (
    <>
      <Hero />
      <WhyChooseUs />
      <About />
      <Puppies />
      <Testimonials />
      <Contact />
    </>
  )
}
