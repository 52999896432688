import Header from 'components/layout/header/header'
import React from 'react'
import './layout.Module.scss'
import Footer from 'components/layout/footer/footer'
import ContactBanner from 'components/layout/contactBanner/contactBanner'

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="layout__container">
      <ContactBanner />
      <Header />
      <div className="layout__body">{children}</div>
      <Footer />
    </div>
  )
}
