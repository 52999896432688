import React, { useState } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import './puppieImageSlideShow.Module.scss'

export default function PuppieImageSlideShow({ images }: { images: string[] }) {
  const [imageIndex, setImageIndex] = useState(0)

  return (
    <div className="puppie__slideshow">
      <div
        className="slideshow__button left"
        onClick={() =>
          setImageIndex((oldIndex) =>
            oldIndex - 1 >= 0 ? oldIndex - 1 : images.length - 1,
          )
        }
      >
        <KeyboardArrowLeft fontSize="inherit" />
      </div>
      <div
        className="slideshow__image"
        style={{ backgroundImage: `url("${images[imageIndex]}")` }}
      >
        <label className="slideshow__index">
          {imageIndex + 1}/{images.length}
        </label>
      </div>
      <div
        className="slideshow__button right"
        onClick={() =>
          setImageIndex((oldIndex) =>
            oldIndex + 1 < images.length ? oldIndex + 1 : 0,
          )
        }
      >
        <KeyboardArrowRight fontSize="inherit" />
      </div>
    </div>
  )
}
