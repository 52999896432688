import React, { useEffect, useState } from 'react'
import colors from 'static/colors'
import Hamburger from 'hamburger-react'
import './mobileMenu.Module.scss'

const links = [
  { text: 'Home', link: '/' },
  { text: 'Puppies', link: '/puppies' },
]

export default function MobileMenu() {
  const [visibility, setVisibility] = useState(false)

  useEffect(() => {
    if (visibility) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
  }, [visibility])

  return (
    <>
      <div className="mobile__menu__button">
        <Hamburger
          toggled={visibility}
          toggle={setVisibility}
          color={colors.primary}
        />
      </div>

      {visibility && (
        <div
          className="mobile__menu__overlay"
          onClick={() => setVisibility(false)}
        />
      )}

      <div
        className={`mobile__menu__container ${
          visibility ? 'mobile__menu__container--active' : ''
        }`}
      >
        {links.map((link) => {
          return (
            <div
              className="mobile__menu__item"
              key={link.link}
              onClick={() =>
                (window.location.href = window.location.origin + link.link)
              }
            >
              <label>{link.text}</label>
            </div>
          )
        })}
      </div>
    </>
  )
}
