import React from 'react'
import './whyChooseUs.Module.scss'
import dog1 from 'assets/dog3.jpeg'
import dog2 from 'assets/Puppies Gallery/dog1.jpeg'
import dog3 from 'assets/Puppies Gallery/dog7.jpeg'
import dog4 from 'assets/Puppies Gallery/dog8.jpeg'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'

const data = [
  {
    image: dog1,
    title: 'Exceptional Quality Breeding',
    text: "We specialize in raising premium, health-tested Cockapoo puppies. Our dedicated team meticulously selects parent dogs for their impeccable pedigree, temperament, and health, ensuring every puppy embodies the breed's best qualities. With a focus on ethical breeding practices, our Cockapoos are raised in a loving environment, receiving early socialization and comprehensive veterinary care. Our commitment to excellence guarantees you a companion with outstanding genetics, intelligence, and a loving disposition. Welcome to a world where quality and care converge, creating lifelong bonds between families and their extraordinary Cockapoo companions.",
  },
  {
    image: dog2,
    title: 'Health Tested Parents',
    text: "Our Cockapoos come from health-tested parents, ensuring optimal genetics and well-being. We prioritize the long-term health of our puppies by meticulously screening parent dogs for hereditary diseases. This dedication results in happy, healthy puppies, giving you peace of mind and a loving, vibrant companion for years to come. Choosing a puppy from health-tested parents means you're welcoming a furry family member with a strong genetic foundation, promoting a lifetime of vitality and joy.",
  },
  {
    image: dog3,
    title: 'Health, Vaccination Records',
    text: "we prioritize your puppy's health and safety. Each pup comes with meticulously documented vaccination records, detailing their immunization history. We believe in transparency, providing you with comprehensive information about your furry friend's health journey. Our commitment to responsible breeding extends to ensuring that your new companion is up-to-date on vaccinations, giving you confidence in their well-being. With our detailed vaccination records, you can focus on creating beautiful memories with your healthy, happy, and immunized Cockapoo.",
  },
  {
    image: dog4,
    title: 'Socialization and Training',
    text: "Our Cockapoos are not just pets, they're family members. We prioritize socialization and training from day one, creating confident, well-adjusted puppies. Raised in a nurturing environment, they're exposed to various stimuli and experiences, ensuring they grow into sociable, adaptable companions. Our expert trainers use positive reinforcement techniques, laying a strong foundation for obedience and good behavior. Whether it's basic commands or leash training, our puppies receive personalized attention, making them a delight to welcome into your home. When you choose one of our Cockapoos, you're not just getting a pet; you're getting a loving, trained companion ready to enrich your life.",
  },
]

export default function WhyChooseUs() {
  return (
    <div className="whyus__container" id="Why Choose us">
      <SectionTitle title="Why Choose us?" />
      <div className="whyus__grid">
        {data.map((item) => {
          return (
            <div className="whyus__card" key={item.title}>
              <img className="background__image" src={item.image} />
              <div className="background__overlay" />
              <label className="title">{item.title}</label>
              <p className="text">{item.text}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
