import React from 'react'
import './puppies.Module.scss'
import PuppiesList from './components/puppiesList/puppiesList'
import dogImage from 'assets/Puppies Gallery/dog9.jpeg'
// import BackButton from 'components/ui/backButton/backButton'

export default function Puppies() {
  return (
    <div>
      {/* <BackButton /> */}
      <div className="puppies__header">
        <img className="puppies__header__image" src={dogImage} />
        <div className="puppies__header__background" />
        <div className="puppies__header__body">
          <h1 className="puppies__header__title">
            Browse through the Gallery of our cute Puppies
          </h1>
          <label className="puppies__header__subtitle">
            Cockapoos are delightful, resulting from the crossbreeding of Cocker
            Spaniels and Poodles. Known for their friendly and affectionate
            nature, they inherit intelligence and hypoallergenic traits from
            their parent breeds. Cockapoos are highly adaptable, making them
            excellent family pets. They boast a soft, curly coat, and their
            playful demeanor endears them to people of all ages.
          </label>
        </div>
      </div>
      <PuppiesList />
    </div>
  )
}
