import React, { useEffect, useState } from 'react'
import facetimeIcon from 'assets/facetime.svg'
import whatsappIcon from 'assets/whatsapp.svg'
import { Entry, EntrySkeletonType } from 'contentful'
import { useContentful } from 'context/contentfulContext'
import { phoneNumber } from 'static/data'
import PuppieImageSlideShow from './components/puppieImageSlideShow/puppieImageSlideShow'
import './puppieHeader.Module.scss'
import PuppieInfo from './components/puppieInfo/puppieInfo'

export default function PuppieHeader({ id }: { id: string }) {
  const [images, setImages] = useState<string[]>([])
  const [dog, setDog] = useState<
    Entry<EntrySkeletonType, undefined, string> | undefined
  >(undefined)
  const { puppies, loading } = useContentful()

  useEffect(() => {
    if (loading) return

    const dog = puppies?.filter(
      (m: Entry<EntrySkeletonType, undefined, string>) =>
        m.fields.id?.toString() == id,
    )[0]

    setDog(dog as Entry<EntrySkeletonType, undefined, string> | undefined)

    //eslint-disable-next-line
    const images = (dog?.fields.images as any[]).map(
      //eslint-disable-next-line
      (image: any) => 'https:' + image.fields.file.url,
    )
    setImages(images)
  }, [loading])

  const handleFacetime = () => {
    if (dog == null) return
    const message =
      "Hello,  I'm interested in a Facetime with this Puppie\n\n" +
      window.location.href

    const smsUrl = 'sms:' + phoneNumber + '&body=' + encodeURIComponent(message)
    window.open(smsUrl)
  }

  const handleWhatsapp = () => {
    const message =
      "Hello,  I'm interested in this Puppie\n\n" + window.location.href

    const whatsappUrl =
      'https://api.whatsapp.com/send?phone=' +
      phoneNumber +
      '&text=' +
      encodeURIComponent(message)

    // Open the WhatsApp URL in a new tab/window
    window.open(whatsappUrl)
  }

  useEffect(() => {
    console.log(dog)
  }, [dog])

  return (
    <>
      <div className="puppie__header">
        <div className="puppie__header__body">
          <PuppieImageSlideShow images={images} />
          <div className="puppie__header__text">
            <div className="puppie__header__text__header">
              <label className="puppie__breed">Cockapoo</label>
              <div className="separator" />
              <PuppieInfo dog={dog} />
            </div>

            <div className="puppie__header__text__footer">
              <div className="puppie__header__price">
                Price{' '}
                {dog?.fields.price != null ? (
                  <span>
                    {dog?.fields.oldPrice && (
                      <span className="puppie__old__price">
                        ${dog.fields.oldPrice as string}
                      </span>
                    )}
                    ${dog?.fields.price as string}
                  </span>
                ) : (
                  <span>Call for Details</span>
                )}
              </div>
              <div className="puppie__header__contact">
                <button
                  className="puppie__contact__button"
                  onClick={handleFacetime}
                >
                  Request Facetime
                  <img src={facetimeIcon} />
                </button>

                <button
                  className="puppie__contact__button"
                  onClick={handleWhatsapp}
                >
                  Chat Whatsapp
                  <img src={whatsappIcon} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
