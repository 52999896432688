import React from 'react'
import './puppies.Module.scss'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import { Entry, EntrySkeletonType } from 'contentful'
import PuppieCard from './components/puppieCard'
import { useContentful } from 'context/contentfulContext'
import { useNavigate } from 'react-router-dom'
import Button from 'components/ui/button/button'

export default function Puppies() {
  const { puppies } = useContentful()
  const navigate = useNavigate()

  return (
    <div className="puppies__container" id="Puppies">
      <SectionTitle
        title="Latest Puppies"
        // subtitle="Puppies looking for a new loving owner"
      />
      <div className="puppies__grid__container">
        {puppies
          ?.sort(
            (a, b) => parseInt(b.fields.id + '') - parseInt(a.fields.id + ''),
          )
          .slice(0, 4)
          .map((dog: Entry<EntrySkeletonType, undefined, string>) => {
            return (
              <PuppieCard
                //eslint-disable-next-line
                images={(dog.fields.images as any[]).map(
                  //eslint-disable-next-line
                  (image: any) => ('https:' + image.fields.file.url) as string,
                )}
                id={dog.fields.id as number}
                variant="full"
                key={dog.fields.id as string}
              />
            )
          })}
      </div>
      <div>
        <Button onClick={() => navigate('/puppies')} variant="tertiary">
          Browse all Puppies
        </Button>
      </div>
    </div>
  )
}
