import React from 'react'
// import heroImage from 'assets/heroImage.jpeg'
import heroImage from 'assets/backgroundImage.png'
import Button from 'components/ui/button/button'
import { useNavigate } from 'react-router-dom'
import './hero.Module.scss'

export default function Hero() {
  const navigate = useNavigate()
  return (
    <div className="hero__area" id="Home">
      <div className="hero__container">
        <div
          className="hero__image"
          style={{
            backgroundImage: `url("${heroImage}")`,
          }}
        ></div>
        <div className="hero__body">
          <h1 className="hero__title">Discover Your New Best Friend</h1>
          <Button
            className="hero__button"
            variant={'primary'}
            color="primary"
            onClick={() => {
              navigate('/puppies')
            }}
          >
            Browse All Puppies
          </Button>
          <label className="hero__info">300+ Raised Puppies</label>
        </div>
      </div>
    </div>
  )
}
