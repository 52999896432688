export default {
  primary: '#b08968',
  secondary: '#ede0d4',

  black5: '#ececec',
  black20: '#cecece',
  black40: '#a3a2a2',
  black60: '#777676',
  black80: '#353535',
}
