import React from 'react'
import './logo.Module.scss'
import { useNavigate } from 'react-router-dom'
import logo from 'assets/logo2-no-background.png'

export default function Logo({
  size = 'medium',
}: {
  size?: 'small' | 'medium' | 'large'
}) {
  const navigate = useNavigate()

  return (
    <div className={'logo__container ' + size} onClick={() => navigate('/')}>
      <img src={logo} />
    </div>
  )
}
