import React from 'react'
// import dogImage from 'assets/Puppies Gallery/dog6.jpeg'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
// import backgroundImage from 'assets/backgroundImage.png'
import './about.Module.scss'

export default function About() {
  return (
    <div
      className="about__container"
      // style={{ backgroundImage: `url("${backgroundImage}")` }}
      id="About"
    >
      <div className="about__background"></div>
      <div className="about__body">
        <div className="about__body__layout">
          {/* <div
            className="about__image"
            style={{ backgroundImage: `url("${dogImage}")` }}
          ></div> */}
          <div className="about__video__container">
            <video className="about__video" controls>
              <source
                src={window.location.href + '/videos/small-puppies.mp4'}
              ></source>
            </video>
          </div>
          <div className="about__text">
            <SectionTitle
              title="About us"
              // subtitle="Your Trusted Source for Canine Companions"
            />

            <div className="about__description">
              <p>
                Welcome to Musketeers Cockapoos. We are a family-operated dog
                breeding business that lies in the heart of new york city and
                extends all around. We are a dedicated team of passionate dog
                lovers who take our extensive experience and expertise in the
                field to commit to raising healthy and happy puppies. We
                prioritize our puppies needs and provide them with a nurturing
                home allowing them to thrive until being placed in your loving
                home.
              </p>
              <p>
                At Musketeers Cockapoos, we understand that every dog is unique
                and holds a special place in the hearts of their owners. Thats
                why we prioritize responsible breeding practices, ensuring the
                well-being and genetic soundness of our furry friends. Our
                breeding program focuses on producing dogs with outstanding
                temperaments, excellent health, and impressive pedigrees.
              </p>
              <p>
                As a family business, we believe that a nurturing environment is
                essential for our puppies&quot; development. Our dedicated team
                members are involved in every aspect of their upbringing,
                ensuring they receive individual attention, socialization, and
                early training. We take great care to expose our puppies to
                various stimuli, allowing them to grow into confident,
                well-rounded dogs who will seamlessly integrate into their new
                homes.
              </p>
              <p>
                At Musketeers Cockapoos, we prioritize transparency and open
                communication with our clients. We provide comprehensive
                information about each puppy, including health records, lineage,
                and any additional details you may require. We encourage you to
                ask questions and actively participate in the process of
                choosing your new family member.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
