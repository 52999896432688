import React, { useState } from 'react'
import { Entry, EntrySkeletonType } from 'contentful'
import './puppieInfo.Module.scss'
import RichTextBox from 'components/ui/richTextBox/richTextBox'
import { Document } from '@contentful/rich-text-types'

export default function PuppieInfo({
  dog,
}: {
  dog: Entry<EntrySkeletonType, undefined, string> | undefined
}) {
  const [index, setIndex] = useState(0)

  return (
    <div className="puppie__info__container">
      <div className="puppie__info__container__header">
        <label
          className={'puppie__info__link ' + (index == 0 ? 'selected' : '')}
          onClick={() => setIndex(0)}
        >
          Puppie Info
        </label>
        <label
          className={'puppie__info__link ' + (index == 1 ? 'selected' : '')}
          onClick={() => setIndex(1)}
        >
          Health Info
        </label>
      </div>
      <div className="puppie__info__body">
        {index == 0 && (
          <div className="pupppie__info">
            {dog?.fields.name && (
              <div className="puppie__info__item">
                Name <span>{dog?.fields.name as string}</span>
              </div>
            )}

            {dog?.fields.age && (
              <div className="puppie__info__item">
                Age <span>{dog?.fields.age as string}</span>
              </div>
            )}

            <div className="puppie__info__item">
              Gender <span>{dog?.fields.gender as string}</span>
            </div>

            {dog?.fields.birthDate && (
              <div className="puppie__info__item">
                Birth Date <span>{dog?.fields.birthDate as string}</span>
              </div>
            )}

            {dog?.fields.color && (
              <div className="puppie__info__item">
                Color <span>{dog?.fields.color as string}</span>
              </div>
            )}

            {dog?.fields.eyes && (
              <div className="puppie__info__item">
                Eyes <span>{dog?.fields.eyes as string}</span>
              </div>
            )}

            {dog?.fields.estimatedWeight && (
              <div className="puppie__info__item">
                Estimated Weight
                <span>{dog?.fields.estimatedWeight as string}</span>
              </div>
            )}

            {dog?.fields.vaccinations && (
              <div className="puppie__info__item">
                Vaccinations <span>{dog?.fields.vaccinations as string}</span>
              </div>
            )}

            {dog?.fields.vetInspection && (
              <div className="puppie__info__item">
                Vet Inspection{' '}
                <span>{dog?.fields.vetInspection as string}</span>
              </div>
            )}
          </div>
        )}
        {index == 1 && (
          <div className="health__info">
            <p>
              {dog?.fields.healthInfo ? (
                <RichTextBox content={dog?.fields.healthInfo as Document} />
              ) : (
                'No Information about Health'
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
