import React from 'react'
import Logo from 'components/ui/logo/logo'
import './header.Module.scss'
import MobileMenu from './components/mobileMenu/mobileMenu'
import { useNavigate } from 'react-router-dom'

export default function Header() {
  const navigate = useNavigate()

  return (
    <>
      <div className="header__container">
        <div className="header__body">
          <Logo size="large" />
          <div className="header__links">
            <label onClick={() => navigate('/')} className="header__link">
              Home
            </label>

            <label
              onClick={() => navigate('/puppies')}
              className="header__link"
            >
              Puppies
            </label>
          </div>

          <div className={'header__mobile'}>
            <MobileMenu />
          </div>
        </div>
      </div>
    </>
  )
}
