import React from 'react'
import './puppieCard.Module.scss'
import whatsappIcon from 'assets/whatsapp.svg'
import facetimeIcon from 'assets/facetime.svg'

export default function PuppieCard({
  images,
  id,
  variant = 'normal',
}: {
  // eslint-disable-next-line
  images: string[]
  id: number
  variant?: 'normal' | 'full'
}) {
  return (
    <div
      onClick={() => (window.location.href = '/puppie/' + id)}
      key={Math.random() * 1000}
      className={'puppies__item ' + (variant === 'full' ? 'full' : '')}
    >
      <div className="facetime__label">
        <label className="facetime__label__text">Request Facetime</label>
        <img className="facetime__label__icon" src={facetimeIcon}></img>
      </div>
      <div className="whatsapp__label">
        <label className="whatsapp__label__text">WhatsApp Chat</label>
        <img className="whatsapp__label__icon" src={whatsappIcon}></img>
      </div>

      <div
        className="puppies__item__image"
        style={{ backgroundImage: `url("${images[0]}")` }}
      ></div>
    </div>
  )
}
