import React from 'react'
import './sectionTitle.Module.scss'

interface SectionTitleProps {
  title: string
  subtitle?: string
}

export default function SectionTitle({ title, subtitle }: SectionTitleProps) {
  return (
    <div className="sectiontitle__container">
      <h2 className="sectiontitle__title">{title}</h2>
      {subtitle && <label className="sectiontitle__subtitle">{subtitle}</label>}
    </div>
  )
}
