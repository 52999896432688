import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

import './FAQ.Module.scss'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>('')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <div className="FAQ__container">
      <label className="FAQ__title">FAQ Section</label>
      <div className="FAQ__separator" />
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>1. Are you a pet store?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, we are not a pet store, we are home based breeders with a
            mission to raise happy, healthy puppies and finding them amazing
            families!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            2. Is the weight and size of my puppy guaranteed?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, it is nearly impossible to make a precise guess. We base the
            estimated weight off of the breed&apsos;s typical size and the
            parent&apos;s weight.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            3. How can you ensure that the puppy I&apos;m taking home is happy
            and healthy?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            As we are the breeders and sellers of your puppy, we are committed
            to raising healthy and happy pups. Your puppy never leaves our care
            until it arrives at your home. Our pups are born and raised indoors
            at our home, Fed high quality diets, given proper age appropriate
            exercise. We have the privilege to monitor the mommies and puppies
            throughout the day and night to ensure every doggy parent and puppy
            is getting the proper care it deserves. Our puppies are vet checked
            and up to date on all vaccinations and dewormers ensuring they are
            as healthy as can be before leaving to their new home.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            4. Why are some puppies more expensive then others?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The prices of our puppies are determined based on many variables,
            including but not limited to, the breed; the estimated adult size;
            the age; and the coloring of the fur and Eyes.Because all of our
            puppies are vet checked before going to their new home, you can rest
            assured that our pricing does not reflect hidden health concerns. If
            you have any questions about a specific puppy and their pricing,
            please don&apos;t hesitate to contact us
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            5. Can I meet a puppy before putting down a deposit?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Of Course, most of our customers put a down payment or deposit
            before meeting the puppy based on pictures or video calls. We prefer
            you to do a video call to show you live action of your puppy.Doing
            this is the most promising way to ensure you get the puppy of your
            choice due to the high demand for the breeds we have. If you are
            adamant about meeting your puppy first, feel free to set up an
            appointment prior to the deposit. However, we cannot hold the puppy
            for you unless the deposit has been officially made and therefore
            cannot promise you the puppy just because you have scheduled an
            appointment. Use your own discretion when making this decision and
            feel free to contact us with questions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            6. Has my puppy received its vaccinations and dewormings? Will my
            puppy need more after we take it home?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our puppies are dewormed, as preventative maintenance at two, four,
            six, eight weeks and every 2 weeks until 12 weeks of age. we
            recommend deworming the puppy every two weeks until twelve weeks of
            age, then monthly until six months of age. You will receive your
            puppy&apos;s medical records when you pick your puppy up, which you
            can take to your vet of choice. All puppies cone with their first
            set if shots. We Will provide you with a copy of those shots for you
            to take to your vet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>7. Does my new puppy come microchipped?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We will microchip your puppy upon request, the microchipping fee is
            not incorporated in the puppies price and is $80 (we do not cover
            the price to register the microchip)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>8. Why won&apos;t my puppy eat?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Don&apos;t be too concerned if your puppy is not eating unless there
            are other symptoms your pup is displaying. Chances are your new baby
            is just understandably nervous as they are in a new environment ,
            However puppies need to eat most constantly. Therefore, this issue
            should not be ignored if the Puppy goes 24 hrs without eating or
            drinking please contact me or your vet. Puppies often avoid eating
            when they are nervous or adjusting to a new environment and require
            excessive encouragement to get back on track. This often means
            giving them more delicious meals than their regular dog food at
            first. For puppies, meals with high protein are best to keep their
            growth healthy and constant.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            9. I think my puppy may be sick, how do I know and what should I do?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Just because your puppy is sleeping a lot or not eating does not
            necessarily mean they are sick. However, it is always better to be
            safe than sorry! If it is a non-emergency but you&apos;re still
            concerned, we recommend contacting us or calling a vet for on-phone
            advice before scheduling an appointment with the vet. If you are too
            concerned to opt for on-phone advice, take your puppy to the vet of
            your choice. Just keep in mind, all of our puppies are given a clean
            bill of health through our routine vet checks before going home with
            you and therefore we are unable to cover the costs of a vet visit
            after you take your pup home. All puppies must see a vet no later
            than 3 days of their arrival to their new home.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
