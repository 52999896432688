import React, { ButtonHTMLAttributes } from 'react'
import './button.Module.scss'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'tertiary'
  color?: 'primary' | 'secondary'
  className?: string
}

export default function Button({
  children,
  variant = 'primary',
  color = 'primary',
  className = '',
  ...otherProps
}: ButtonProps) {
  return (
    <button
      className={`button button--variant--${variant} button--color--${color} ${className}`}
      {...otherProps}
    >
      {children}
    </button>
  )
}
