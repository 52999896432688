import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import { Phone, TravelExplore } from '@mui/icons-material'
import './contact.Module.scss'
import { phoneNumber } from 'static/data'

const latitude = 40.792774
const longitude = -73.847591

export default function Contact() {
  return (
    <div className="contact__container" id="Contact">
      <SectionTitle
        title="Contact Details"
        subtitle="Get in touch to schedule an appointment or browse our gallery of available puppies"
      />
      <div className="contact__body">
        <div className="contact__card">
          <div className="contact__icon">
            <TravelExplore fontSize="inherit" />
          </div>

          <label>Queens, New York</label>
          <a
            target="_blank"
            href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`}
            rel="noreferrer"
          >
            <span>View Map & Directions</span>
          </a>
        </div>

        <a className="contact__card" type="phone">
          <div className="contact__icon">
            <Phone fontSize="inherit" />
          </div>
          <a href={'tel:+' + phoneNumber}>
            Phone: <span>{phoneNumber}</span>
          </a>
          <a href={'mailto:' + 'contact@musketeers-cockapoos.com'}>
            Email: <span>contact@musketeers-cockapoos.com</span>
          </a>
        </a>
      </div>
    </div>
  )
}
