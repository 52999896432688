import React from 'react'
import './contactBanner.Module.scss'
import { phoneNumber } from 'static/data'

export default function ContactBanner() {
  return (
    <div className="banner__container">
      <a type="phone" href={'tel:' + phoneNumber} className="banner__body">
        <label className="banner__title">
          <label>
            <span>Click here to get in touch!</span> - Up to <b>40% Discount</b>
          </label>
          <label>
            <span>Click here to get in touch!</span> - Up to <b>40% Discount</b>
          </label>
        </label>
        <label className="banner__title">
          <label>
            <span>Click here to get in touch!</span> - Up to <b>40% Discount</b>
          </label>
          <label>
            <span>Click here to get in touch!</span> - Up to <b>40% Discount</b>
          </label>
        </label>
        <div className="banner__links">
          {/* <a href="tel:+1234567890" className="banner__link" type="phone">
            <Phone fontSize="inherit" />
            (1234) 567 890
          </a>

          <a href="mailto:test@example.com" className="banner__link">
            <Email fontSize="inherit" />
            test@example.com
          </a> */}
        </div>
      </a>
    </div>
  )
}
